import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Section } from "../../layout";
import { FindOutMoreButton, Image, DesktopRightImage, PhoneImage, TrailguidePlugin } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Image src="solutions/hallingdal_gol.jpg" className="my-12 mx-auto" mdxType="Image" />
    <p className="justify-text leading-relaxed text-xl">
  Hallingdal is Norway's new mountain bike region with six individual
  destinations. Two hours north of Oslo you find the beautiful valley full of
  natural bike trails, family friendly flow trails, bike parks, pump tracks, and
  also Europes longest machine build trail (17km, 1100hm).
    </p>
    <Section mdxType="Section" />
    <h4>{`A digital platform for the whole region`}</h4>
    <DesktopRightImage className="-mt-8" src="solutions/nesbyen_sykkelkart.jpg" mdxType="DesktopRightImage" />
    <PhoneImage src="solutions/nesbyen_sykkelkart.jpg" mdxType="PhoneImage" />
    <p className="justify-text leading-relaxed text-xl">
  <a href="https://rides.no">Hallingdal Rides</a> wants to communicate the many
  possibilities the valley has to offer in a digital and modern way. For guests
  it becomes more attractive to stay longer when they see the possibilities of
  all six destinations. Each has its own character and is worth a visit.
    </p>
    <Section mdxType="Section" />
    <h4>{`Interactive maps for all websites`}</h4>
    <p className="justify-text leading-relaxed text-xl">
  With Trailguide, Hallingdal is able to manage all the activities on one
  platform. The content is embeded on the websites of all individual
  destinations and on the <a href="https://rides.no">Hallingdal Rides</a>{" "}
  website. Interactive maps with a list, search and filter make it easy for
  guests to see what the region has to offer.
    </p>
    <br />
    <TrailguidePlugin content="/x49" theme="light" mdxType="TrailguidePlugin" />
    <p className="text-xs -mt-8 mb-12 text-center">
  Interactive map of Hallingdal with MTB trails of all six destinations.
    </p>
    <Section mdxType="Section" />
    <div className="flex sm:flex-no-wrap flex-wrap">
  <Image src="solutions/geilo_biking2.jpg" className="mx-4" mdxType="Image" />
  <Image src="solutions/scan_code_nesbyen.jpg" className="mx-4" mdxType="Image" />
    </div>
    <Section mdxType="Section" />
    <h4>{`Easy access`}</h4>
    <DesktopRightImage className="-mt-1 w-32" src="solutions/nesbyen_qrcode.jpg" mdxType="DesktopRightImage" />
    <PhoneImage src="solutions/nesbyen_qrcode.jpg" mdxType="PhoneImage" />
    <p className="justify-text leading-relaxed text-xl">
  Trailguides technology allows easy and quick access to the information and the
  features of the app. Hallingdal created collections for the individual
  destinations. These collections show selected content and are accessible
  through qr-codes. Test it yourself with this code.
    </p>
    <TrailguidePlugin content="/x4o" theme="light" mdxType="TrailguidePlugin" />
    <p className="text-xs -mt-8 mb-12 text-center">
  Interactive map of mountain bike trails in Nesbyen
    </p>
    <Section mdxType="Section" />
    <h4>{`Signs and prints connected to digital information`}</h4>
    <DesktopRightImage className="-mt-8" src="solutions/nesbyen_map.jpg" mdxType="DesktopRightImage" />
    <PhoneImage src="solutions/nesbyen_map.jpg" mdxType="PhoneImage" />
    <p className="justify-text leading-relaxed text-xl">
  Each destination has its own signage and print products. Guests can scan the
  qr-code to get to the same map as on the infoboard, but with up-to-date
  information and the functionality of the app.
    </p>
    <Section mdxType="Section" />
    <p className="px-12 pt-6 bg-gray-100 text-8xl font-bold">“</p>
    <p className="px-12 bg-gray-100 justify-text italic leading-relaxed text-xl">
  Trailguide is functional, user-friendly, very innovative and flexible. It is a
  great advantage to be able to communicate with the people who work at
  Trailguide to be able to convey our needs. All in all, the service works very
  well for our purposes - there is little to put your finger on!”
  <br />
  <br />
  Lars Storheim, Hallingdal Rides
    </p>
    <p className="px-12 bg-gray-100 text-8xl font-bold text-right">“</p>
    <div className="flex justify-center mt-12">
  <FindOutMoreButton to="/solutions/destination" className="text-xl" mdxType="FindOutMoreButton">
    Find out more about our destination solutions
  </FindOutMoreButton>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      